<template lang="pug">
    .another-person-info
        ._header
            ._header-group
                ._header-icon(:class="leaderTypeIcon" v-if="leaderTypeIcon")
                    svg-icon(
                        :icon-name="leaderTypeIcon",
                        addedCatalog="leader-types",
                        :added-class="leaderTypeIcon"
                        v-if="leaderTypeIcon"
                    )
                ._header-content
                    ._header-content-name {{ person.name === "Newbie" ? locale("newbie", {}, true) : person.name }}
                        svg-icon._is-celebrity(icon-name="star" v-if="currentPerson.celebrity_status.celebrity")
                    ._header-content-type
                        span(v-if="isMyProfile")
                            span(v-if="person.leader_type === '_locked_'" @click="confirmOnBoardingSwitch('PSYCHOTIC_DETECTION')")._psychotype-locked {{ locale("getType") }}
                            span(v-else) {{ person.leader_type }}
                        span(v-else) {{ person.leader_type === "_hidden_" ? locale("typeHidden") : person.leader_type }}
            right-aside-social

        ._content
            ._content-item
                ._content-item-group
                    ._content-item-label {{ locale("web") }}
                    a(:href="web" target="_blank" rel="nofollow" v-if="web")._content-item-website {{ web }}
                    ._content-item-website(v-else) {{ locale("webUndefined") }}
                ._content-item-group
                    ._content-item-label {{ locale("selfEsteem") }}
                    ._content-item-value {{ self_esteem }}
                ._content-item-group
                    ._content-item-label {{ locale("occupation") }}
                    ._content-item-value {{ occupation }}
                ._content-item-value.-city(v-if="city")
                    // svg-icon(iconName="russia", addedCatalog="flags")
                    span {{ country }}{{ city }}
            ._content-item
                ._content-item-group
                ._content-item-label {{ locale("personalInfo") }}
                ._content-item-value(v-if="age") {{ age }} {{ declination(age, [locale("age_1"), locale("age_2"), locale("age_3")]) }}
                ._content-item-value(v-if="person.personal_data.height") {{ person.personal_data.height }} {{ locale("height") }}
                ._content-item-value(v-if="person.personal_data.weight") {{ person.personal_data.weight }} {{ locale("weight") }}
                ._content-item-value(v-if="person.personal_data.marriage_status") {{ person.personal_data.marriage_status }}
                ._content-item-value {{ children }}

</template>

<script>
import UiButton from "@/components/ui/ui-button/ui-button";
import declination from "@/utils/declination";
import RightAsideSocial from "@/components/app/right-aside/social/social";

export default {
    name: "AnotherPersonInfo",
    components: { RightAsideSocial, UiButton },
    props: {
        initialInfo: {
            type: Object,
            default: () => {},
            required: true,
        },
        person: {
            type: Object,
            default: () => {},
            required: true,
        },
    },
    data() {
        return {
            city: "",
            country: "",
            occupation: "",
            self_esteem: "",
            web: "",
        };
    },
    computed: {
        personTypeIcons: (state) => state.$store.getters["personTypeIcons"],
        leaderTypeIcon: (state) =>
            state.personTypeIcons[state.person.leader_type],
        age: function () {
            if (!this.person.personal_data_date_of_birth) return false;
            let today = new Date(),
                birthDate = new Date(this.person.personal_data.date_of_birth),
                age = today.getFullYear() - birthDate.getFullYear(),
                m = today.getMonth() - birthDate.getMonth();

            if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
                age = age - 1;
            }

            return age;
        },
        children: function () {
            const count = this.person.personal_data.number_of_children;
            if (!count) return this.locale("noChildren");

            return `${count} ${this.declination(count, [
                this.locale("children_1"),
                this.locale("children_2"),
                this.locale("children_3"),
            ])}`;
        },
    },
    created() {
        const info = this.initialInfo;
        this.city = info.city ? `, ${info.city}` : "";
        this.country = info.country ? info.country : "";
        this.occupation = info.occupation ? info.occupation : this.locale("occupationUndefined");
        this.self_esteem = info.self_esteem ? info.self_esteem : this.locale("selfEsteemUndefined");
        this.web = info.web ? info.web : "";
    },
    methods: {
        declination,
    },
};
</script>

<style lang="scss" scoped>
@import "./another-person-info.scss";
</style>
