<template lang="pug">
    .avatar-gallery
        ._current-photo
            transition(name="avatar-fade")
                loading(v-if="uploadLoading")._upload-loading
            transition(name="avatar-fade")
                ._error(v-if="avatar.changed && !avatar.valid && error.title")
                    ._error-title {{ errorTitle }}
                    ._error-message {{ errorMessage }}

            user-photo(:micro="false", :photo="avatar.preview" type="main-avatar")
            label(for="avatar" :class="{error: avatar.changed && !avatar.valid && error.title}" v-if="isMyProfile")._upload-btn {{ uploadText }}
                input#avatar(type="file" @change="uploadAvatar")._file-input
</template>

<script>
import UserPhoto from "@/components/ui/user-photo/user-photo";
import gql from "graphql-tag";
import getPhotoUploadUrlQuery from "@/graphql/queries/getPhotoUploadUrl.query.graphql";
import { photoUploadMixins } from "@/mixins/photoUploadMixins";
import setPersonPhotoMutation from "@/graphql/mutations/setPersonPhoto.mutation.graphql";
import taskGetQuery from "@/graphql/queries/taskGet.query.graphql";

export default {
    name: "AvatarGallery",
    components: { UserPhoto },
    mixins: [photoUploadMixins],
    props: {
        initialAvatar: {
            type: String,
            default: "",
            required: true,
        },
    },
    data() {
        return {
            uploadLoading: false,
            photoUploadUrl: "",
            error: {
                title: "",
                message: "",
            },
            avatar: {
                changed: false,
                valid: false,
                preview: "",
                photo: "",
            },
        };
    },
    computed: {
        errorTitle: (state) => state.localeError(state.error.title),
        errorMessage: (state) => state.localeError(state.error.message),
        uploadText: (state) =>
            state.avatar.changed && !state.avatar.valid
                ? state.locale("retry")
                : state.locale("new"),
    },
    watch: {
        uploadLoading(status) {
            if (!status) {
                setTimeout(() => {
                    this.error = {
                        title: "",
                        message: "",
                    };
                    this.avatar.changed = false;
                }, 10000);
            }
        },
    },
    created() {
        this.avatar.preview = this.initialAvatar;
    },
    methods: {
        async uploadAvatar({ target }) {
            if (!target.files[0].size && !target.isDecoded) {
                return;
            }

            this.uploadLoading = true;
            this.avatar.changed = true;

            await this.getPhotoUploadUrl();

            const $this = this,
                xhr = new XMLHttpRequest(),
                token =
                    localStorage.getItem("token") || this.$cookies.get("token");

            xhr.open("POST", this.photoUploadUrl + "&category=avatar", true);
            xhr.setRequestHeader("Authorization", token);

            xhr.onreadystatechange = async function () {
                if (this.readyState === XMLHttpRequest.DONE) {
                    if (this.status === 200) {
                        const response = JSON.parse(this.responseText);

                        if (response.error === null) {
                            $this.avatar.photo =
                                response.http_host +
                                response.folder_name +
                                response.file_name;

                            await $this.setAvatarToPerson();
                        } else {
                            const errors = $this.handlerError(
                                response.error,
                                true,
                            );

                            $this.error.title = errors.title;
                            $this.error.message = errors.message;

                            $this.uploadLoading = false;
                            $this.avatar.valid = false;

                            return false;
                        }
                    } else {
                        $this.uploadLoading = false;
                        $this.avatar.valid = false;

                        $this.error.title = "unknownError";
                        $this.error.message = "retry";
                    }
                }
            };
            xhr.send(target.files[0]);
        },
        async setAvatarToPerson() {
            await this.$apollo
                .mutate({
                    mutation: gql(setPersonPhotoMutation),
                    variables: {
                        uuid: this.myPerson.uuid,
                        photo: this.avatar.photo,
                        category: "AVATAR",
                    },
                })
                .then((response) => {
                    const taskId = response.data.setPersonPhoto.taskId;
                    let pendingCounter = 0;
                    let intervalId;

                    intervalId = setInterval(() => {
                        this.$apollo
                            .query({
                                query: gql(taskGetQuery),
                                variables: {
                                    taskId,
                                },
                                fetchPolicy: "no-cache",
                            })
                            .then((response) => {
                                switch (response.data.taskGet.status) {
                                    case "COMPLETED":
                                        clearInterval(intervalId);
                                        this.$store.dispatch("person/setAvatar", {
                                            category: "avatar",
                                            photo: this.avatar.photo,
                                        });
                                        this.uploadLoading = false;
                                        this.avatar.preview = this.avatar.photo;
                                        this.avatar.valid = true;
                                        break;

                                    case "ERROR":
                                        clearInterval(intervalId);
                                        this.uploadLoading = false;
                                        this.avatar.valid = false;

                                        this.error = this.handlerError(
                                            response.data.taskGet.error,
                                            true,
                                        );

                                        break;

                                    case "PENDING":
                                        if (pendingCounter >= 10) {
                                            clearInterval(intervalId);
                                            this.uploadLoading = false;
                                            this.avatar.valid = false;
                                            this.error.title = "unknownError";
                                            this.error.message = "retry";
                                        }
                                        pendingCounter++;
                                        break;
                                }
                            })
                            .catch((r) => {
                                if (r.graphQLErrors) {
                                    const error =
                                        r.graphQLErrors[0].extensions.code;

                                    if (error === 1002) {
                                        return;
                                    }
                                }
                                clearInterval(intervalId);

                                this.error.title = "unknownError";
                                this.error.message = "retry";
                            });
                    }, 1200);
                })
                .catch((e) => {
                    const error = e.graphQLErrors[0]?.extensions?.code;
                    console.log(error);
                    if (error) {
                        if (error === 1211) {
                            this.uploadLoading = false;
                            this.avatar.valid = false;
                            this.error.title = "attention";
                            this.error.message = "AVATAR_LIMIT_REACHED";
                            return;
                        }
                    }

                    this.uploadLoading = false;
                    this.avatar.valid = false;
                    this.error.title = "unknownError";
                    this.error.message = "retry";
                });
        },
        async getPhotoUploadUrl() {
            await this.$apollo
                .query({
                    query: gql(getPhotoUploadUrlQuery),
                    fetchPolicy: "no-cache",
                })
                .then((r) => {
                    this.photoUploadUrl = r.data.getPhotoUploadUrl;
                });
        },
    },
};
</script>

<style lang="scss" src="./avatar-gallery.scss"></style>
