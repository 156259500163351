<template lang="pug">
    .available-social
        a._item(
            v-for="(item, idx) in socials",
            :key="idx",
            :href="'//' + item.url",
            target="_blank"
        )
            svg-icon._item-icon(
                :iconName="item.icon",
                :class="'available-social__item-icon-' + item.name"
            )
            ._item-url(v-if="item.url") {{ item.url }}
            ._item-text(v-else) {{ locale("notSelected") }}
</template>

<script>
export default {
    name: "AvailableSocial",
    data: () => ({
        socials: [
            { name: "vk", icon: "vk", url: null },
            { name: "instagram",  icon: "instagram",  url: null },
            { name: "facebook", icon: "facebook", url: null },
            { name: "telegram", icon: "telegram", url: null },
            { name: "mail", icon: "mail", url: null },
        ],
    }),
};
</script>

<style lang="scss" scoped>
@import "./available-social.scss";
</style>
