<template lang="pug">
    .person
        transition(name="fadeIn")
            loading(v-if="loading")
        person-mobile-header
        personal-recommendations._ctrl-btn(type="aside" v-if="isMyProfile && personExternalIdPresent")
        ._circle(v-if="personExternalIdPresent && isMyProfile")
            choose-want(:type="decorationType" outerWaveEnter="true" :outerLineWave="!photoCompareStatus" :innerFillWave="!photoCompareStatus" :innerWave="!photoCompareStatus" :sections="!photoCompareStatus")
            template
                person-description(v-if="!photoCompareStatus" @changeCategory="changeCircleCategory" @change-decoration-type="changeDecorationType")._description-wrap
                compare-with-photo(v-else="photoCompareStatus" :service="activeService")

        ._content(:class="{'is-auth': isUserAuth}")
            ui-button(v-if="isMyProfile" text="МОЯ ВИЗИТКА" color="gold" size="full" @click.native="showPersonMobileBusinessCardPreview = !showPersonMobileBusinessCardPreview")._preview-btn
            transition-expand
                person-mobile-business-card-preview(
                    v-if="showPersonMobileBusinessCardPreview && isMyProfile",
                    @close="showPersonMobileBusinessCardPreview = false"
                )
            //connect-social.connect-social(v-if="isMyProfile")

            person-mobile-box(:title="compareTitle" :full="true" v-show="showCompares && personExternalIdPresent")
                personCompareMobile(slot="content" type="compare" :category="circleCategory" @changeVisible="changeComparesVisible")

            person-mobile-box(:title="indicatorTitle" :full="true" v-show="showIndicators && personExternalIdPresent")
                personCompareMobile(slot="content" type="indicator" :category="circleCategory" @changeVisible="changeIndicatorsVisible")

            person-mobile-box(:title="aggressionTitle" :full="true" v-show="showAggressions && personExternalIdPresent")
                personCompareMobile(slot="content" type="aggression" :category="circleCategory" @changeVisible="changeAggressionsVisible")

            legal-links-mobile
</template>

<script>
import PersonDescription from "./person-description/person-description";
import ChooseWant from "../auth/choose-want/choose-want";
import CompareWithPhoto from "./compare-with-photo/compare-with-photo";
import PersonMobileHeader from "./person-mobile-header/person-mobile-header";
import personMobileBox from "./person-mobile-box/person-mobile-box";
import personCompareMobile from "./person-compare-mobile/person-compare-mobile";
import personalRecommendations from "./personal-recommendations/personal-recommendations";
import ServiceMenu from "../app/service-menu/service-menu";
import ChangePhotos from "./change-photos/change-photos";
import { descriptionMixins } from "../../mixins/descriptionMixins";
import DescriptionPopup from "../app/description-popup/description-popup";
import UserRecommendationsMobile from "./user-recommendations-mobile/user-recommendations-mobile";
import UiButton from "@/components/ui/ui-button/ui-button";
import PersonMobileBusinessCard from "@/components/person/person-mobile-business-card/person-mobile-business-card.vue";
import ConnectSocial from "@/components/person/connect-social/connect-social";
import AvailableSocial from "@/components/person/available-social/available-social";
import PersonMobileBusinessCardPreview from "./person-mobile-business-card-preview/person-mobile-business-card-preview.vue";
import TransitionExpand from "@/components/ui/transition-expand/transition-expand";
import LegalLinksMobile from "@/components/legal-links/mobile";

export default {
    name: "Person",
    metaInfo() {
        return {
            title: `${this.name} - подробный анализ личности человека искусственным интеллектом`,
            meta: [
                {
                    skip: typeof this.name === "undefined",
                    name: "description",
                    content: `На странице подробно описан характер, лидерские качества, темперамент, тип мышления личности ${
                        this.name
                    }. Также можно узнать свою похожесть и совместимость с ${
                        this.gender === "F" ? "ней" : "ним"
                    }`,
                    vmid: "description",
                },
                {
                    skip: typeof this.name === "undefined",
                    property: "og:description",
                    content: `На странице подробно описан характер, лидерские качества, темперамент, тип мышления личности ${
                        this.name
                    }. Также можно узнать свою похожесть и совместимость с ${
                        this.gender === "F" ? "ней" : "ним"
                    }`,

                    vmid: "og_description",
                },
                {
                    skip: typeof this.name === "undefined",
                    name: "keywords",
                    content:
                        this.name.split(" ").join(", ").toLocaleLowerCase() +
                        ", характер, темперамент, мышление, совместимость, похожесть",
                    vmid: "keywords",
                },
                {
                    skip: this.excludeFromIndexing === false,
                    name: "robots",
                    content: "noindex,nofollow",
                },
                {
                    skip: this.excludeFromIndexing === false,
                    name: "googlebot",
                    content: "noindex",
                },
                {
                    skip: typeof this.name === "undefined",
                    property: "og:type",
                    content: "article",
                    vmid: "og_type",
                },
                {
                    skip: !this.myAvatar,
                    property: "og:image",
                    content: this.myAvatar,
                },
            ],
        };
    },
    components: {
        LegalLinksMobile,
        UserRecommendationsMobile,
        DescriptionPopup,
        ChangePhotos,
        ServiceMenu,
        PersonMobileHeader,
        CompareWithPhoto,
        ChooseWant,
        PersonDescription,
        personMobileBox,
        personalRecommendations,
        personCompareMobile,
        UiButton,
        PersonMobileBusinessCard,
        ConnectSocial,
        AvailableSocial,
        PersonMobileBusinessCardPreview,
        TransitionExpand,
    },
    mixins: [descriptionMixins],
    data: () => ({
        loading: true,
        photoCompareStatus: false, // Нужно ли сейчас покаызвать интерфейс сравнения по фото
        circleCategory: -1,
        showCompares: false,
        showIndicators: true,
        showAggressions: false,
        activeService: {},
        showPeopleRecommendation: true,
        legalToggleActive: false,
        showPersonMobileBusinessCardPreview: false,
        decorationType: "person",
    }),
    computed: {
        uuid: (state) => state.$store.getters["person/uuid"],
        avatar: (state) => state.$store.getters["person/photos"].avatar,
        name: function () {
            if (this.$store.getters["person/name"] === "Newbie") {
                if (this.isMyProfile) {
                    return this.locale("you");
                }
                return this.locale("user");
            }
            return this.$store.getters["person/name"];
        },
        gender: function () {
            return this.$store.getters["person/gender"];
        },
        isCelebrity: (state) => state.$store.getters["person/isCelebrity"],
        anfasPhoto: (state) => state.$store.getters["person/photos"].anfas,
        profilePhoto: (state) => state.$store.getters["person/photos"].profile,
        myUserRoles: (state) => state.$store.getters["user/myUserRoles"],
        photos: (state) => ({
            anfas: state.anfasPhoto,
            profile: state.profilePhoto,
        }),
        personName: (state) => state.$store.getters["person/name"],
        compareTitle: function () {
            if (this.isMyProfile) {
                return this.locale("compare.fromMe");
            }

            if (this.personName === "Newbie") {
                return this.locale("compare.fromUnnamedUser");
            }

            return this.locale("compare.fromNamedUser", {user: this.personName});
        },
        indicatorTitle: function () {
            if (this.isMyProfile) {
                return this.locale("indicator.fromMe");
            }

            if (this.personName === "Newbie") {
                return this.locale("indicator.fromUnnamedUser");
            }

            return this.locale("indicator.fromNamedUser", {user: this.personName});
        },
        aggressionTitle: function () {
            if (this.isMyProfile) {
                return this.locale("aggression.fromMe");
            }

            if (this.personName === "Newbie") {
                return this.locale("aggression.fromUnnamedUser");
            }

            return this.locale("aggression.fromNamedUser", {user: this.personName});
        },
    },
    watch: {
        async "$route.params.uuid"() {
            this.loading = true;
            await this.$store.dispatch("person/clearPersonInfo");
            this.photoCompareStatus = false;
            window.scrollTo(0, 0);
        },
        personExternalIdPresent() {
            setTimeout(() => {
                this.loading = false;
            }, 200);
        },
    },
    created() {
        this.$bus
            .$on("showPhotoCompare", (service) => {
                // service name передаётся в сравнение по фото, чтобы пользователь видел, в каком разеделе он покупает услугу
                this.activeService = service;
                this.photoCompareStatus = true;
                if (this.isMobileView) {
                    let el = document.querySelector(".person");
                    el.scrollIntoView(true);
                }
            })
            .$on("backToPersonDescription", () => {
                this.photoCompareStatus = false;
            });
    },
    mounted() {
        this.loading = false;
    },
    beforeMount() {
        this.$nextTick(() => {
            if (this.isMyProfile) {
                if(this.myPerson.extras.external_id === null || this.myPerson.extras.external_id === "405534") return; 
                if (!this.myPerson.photos.avatar) {
                    console.log(3);
                    this.showTips("avatar");
                } else {
                    console.log(4);
                    this.showTips("recommendations");
                }
            }
        });
    },
    destroyed() {
        this.$bus.$off("showPhotoCompare").$off("backToPersonDescription");
    },
    methods: {
        changeCircleCategory(category) {
            this.circleCategory = category;
        },
        changeComparesVisible(status) {
            this.showCompares = status;
        },
        changeIndicatorsVisible(status) {
            this.showIndicators = status;
        },
        changeAggressionsVisible(status) {
            this.showAggressions = status;
        },
        changeDecorationType(type) {
            this.decorationType = type;
        },
    },
};
</script>

<style lang="scss">
@import "person";
</style>
