<template lang="pug">
    perfect-scrollbar.another-person
        transition(name="fadeIn")
            loading(v-if="!personReady")
        ._wrap(v-if="personReady")
            ._left
                ._left-wrap
                    ._left-item
                        avatar-gallery(:initial-avatar="avatar" data-tip="avatar")
                    friend-actions._friend-actions(v-if="isUserAuth && !isMyProfile" :refresh="refresh")
                    ._left-item
                        ._left-item-title {{ isMyProfile ? locale("rating") : locale("person-rate") }}
                        SocialRating(:initial-rating="person.ratings")
            ._right
                ._right-item
                    another-person-info(:initial-info="person.info" :person="person.person")
                ._right-item(v-if="!isMyProfile")
                    another-person-actions(@friend-request="refreshFriendingStatus")
                ._right-item(v-if="!isMyProfile")
                    ._compare-ctrl
                        button._compare-ctrl-item(@click="compareType = 'compare'" :class="{active: compareType === 'compare'}") {{ locale("compare-with-me") }}
                        button._compare-ctrl-item(@click="compareType = 'indicator'" :class="{active: compareType === 'indicator'}") {{ locale("more") }}
                        button._compare-ctrl-item(@click="compareType = 'aggression'" :class="{active: compareType === 'aggression'}")  {{ locale("aggression") }}
                    person-compare-mobile(:type="compareType" view="desktop" :category="-1")._compare
                another-person-business-card(:person="person")
</template>

<script>
import AnotherPersonInfo from "./another-person-info/another-person-info.vue";
import AnotherPersonActions from "./another-person-actions/another-person-actions.vue";
import AnotherPersonBusinessCard from "./another-person-business-card/another-person-business-card.vue";
import SocialRating from "@/components/person/social-rating/social-rating";
import gql from "graphql-tag";
import personProfileQuery from "@/graphql/queries/personProfile.query.graphql";
import FriendActions from "@/components/app/left-aside/friend-actions/friend-actions";
import TransitionExpand from "@/components/ui/transition-expand/transition-expand";
import MobileAvatarGallery from "@/components/person/mobile-avatar-gallery/mobile-avatar-gallery";
import AvatarGallery from "@/components/person/avatar-gallery/avatar-gallery";
import PersonCompareMobile from "@/components/person/person-compare-mobile/person-compare-mobile";

export default {
    name: "AnotherPerson",
    components: {
        PersonCompareMobile,
        AvatarGallery,
        MobileAvatarGallery,
        TransitionExpand,
        FriendActions,
        SocialRating,
        AnotherPersonInfo,
        AnotherPersonActions,
        AnotherPersonBusinessCard,
    },
    data: () => ({
        person: {},
        personReady: false,
        refresh: false,
        compareType: "compare",
    }),
    computed: {
        avatar: (state) => state.$store.getters["person/photos"].avatar || "",
        uuid: (state) => state.$route.params.uuid,
    },
    watch: {
        uuid() {
            this.getPersonProfile();
        },
        personReady(status) {
            if (status) {
                this.$nextTick(() => {
                    if (this.isMyProfile) {
                        if(this.myPerson.extras.external_id === null || this.myPerson.extras.external_id === "405534") return;
                        if (!this.myPerson.photos.avatar) {
                            console.log(1);
                            this.showTips("avatar");
                        } else {
                            console.log(1);
                            this.showTips("recommendations");
                        }
                    }
                });
            }
        },
    },
    async created() {
        this.personReady = false;
        await this.getPersonProfile();
    },
    methods: {
        async getPersonProfile() {
            this.personReady = false;
            this.person = {};
            await this.$apollo
                .query({
                    query: gql(personProfileQuery),
                    variables: {
                        id: this.uuid,
                    },
                    fetchPolicy: "no-cache",
                })
                .then((r) => {
                    this.person = r.data.personProfile;
                    setTimeout(() => {
                        this.personReady = true;
                    }, 500);
                });
        },
        refreshFriendingStatus() {
            this.refresh = true;
            setTimeout(() => {
                this.refresh = false;
            }, 500);
        },
    },
};
</script>

<style lang="scss">
@import "./another-person.scss";
</style>
