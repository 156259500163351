<template lang="pug">
    .person-mobile-box
        ._head
            ._title(v-html="title")
            //router-link(:to="historyUrl" v-if="historyUrl")._history-link {{ locale("history") }}
        ._content(:class="{full: full}")
            slot(name="content")
</template>

<script>
export default {
    name: "PersonMobileBox",
    props: {
        title: {
            required: true,
            default: "",
            type: String,
        },
        historyUrl: {
            type: Object,
            required: false,
            default: () => ({}),
        },
        full: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
};
</script>

<style src="./person-mobile-box.scss" lang="scss"></style>
