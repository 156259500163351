<template lang="pug">
    .person-info
        ._wrap
            ._photos(@click="isShowAllPhotos = true" v-click-outside="hideAllPhotos" :class="{'all-photos': isShowAllPhotos}")
                mobile-avatar-gallery(:show-all="isShowAllPhotos" v-if="isMyProfile" @photo-deleted="isShowAllPhotos = true" @change-avatar-count="changeAvatarCount" data-tip="avatar")
                user-photo(:photo="avatar" :micro="false" v-else)._photos-item
            ._content(v-if="!isShowAllPhotos")
                ._content-item.-requests
                    router-link._new-requests(v-if="friendRequestsCounter && isMyProfile" to="/persons/requests") У вас {{ friendRequestsCounter }} {{ declination(friendRequestsCounter, ["новая заявка в друзья", "новые заявки  в друзья", "новых заявок  в друзья"]) }}
                ._content-item {{ locale("age") }}
                    span(v-if="age") {{ age }} {{ declination(age, [locale("age_1"), locale("age_2"), locale("age_3")]) }}
                    span(v-else) {{ locale("no") }}
                ._content-item {{ locale("children") }}
                    span {{ children }}
                ._content-item {{ locale("height") }}
                    span(v-if="height") {{ height }}
                    span(v-else) {{ locale("no") }}
                ._content-item {{ locale("weight") }}
                    span(v-if="weight") {{ weight }}
                    span(v-else) {{ locale("no") }}
                ._content-item.-full(v-if="website")
                    span {{ website }}
                ._content-subscribtions(v-if="isMyProfile && !profilePreview" data-tip="subscriptions")
                    ._content-subscribtions-text {{ locale("subscriptions") }}
                    subscriptions-widget
                    //subscriptions-widget-categories(:categories="categories")
        ._no-avatars(v-if="!avatarCount && profilePreview") {{ locale("photo-error") }}
</template>

<script>
import SubscriptionsWidget from "@/components/subscriptions/components/subscriptions-widget/subscriptions-widget";

import UiButton from "@/components/ui/ui-button/ui-button";
import { friendMixins } from "@/mixins/friendMixins";
import MobileAvatarGallery from "@/components/person/mobile-avatar-gallery/mobile-avatar-gallery";
import UserPhoto from "@/components/ui/user-photo/user-photo";
import declination from "@/utils/declination";
import gql from "graphql-tag";
import personProfileQuery from "@/graphql/queries/personProfile.query.graphql";

export default {
    name: "PersonInfo",
    components: {
        UserPhoto,
        MobileAvatarGallery,
        UiButton,
        SubscriptionsWidget,
    },
    mixins: [friendMixins],
    props: {
        profilePreview: {
            required: false,
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        friendsTotal: null,
        isShowAllPhotos: false,
        friendRequestsCounter: null,
        avatarCount: 0,
        isPersonProfileReady: false,
        profile: {
            person: {},
        },
    }),
    computed: {
        avatar: (state) => state.$store.getters["person/photos"].avatar,
        height: (state) => state.profile.person.personal_data?.height,
        weight: (state) => state.profile.person.personal_data?.weight,
        marriageStatus: (state) =>
            state.profile.person.personal_data?.marriage_status,
        age: function () {
            if (!this.profile.person.personal_data?.date_of_birth) return false;
            let today = new Date(),
                birthDate = new Date(
                    this.profile.person.personal_data.date_of_birth,
                ),
                age = today.getFullYear() - birthDate.getFullYear(),
                m = today.getMonth() - birthDate.getMonth();

            if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
                age = age - 1;
            }

            return age;
        },
        children: function () {
            const count = this.profile.person.personal_data?.number_of_children;
            if (!count) return this.locale("no_children");

            return `${count} ${this.declination(count, [
                this.locale("children_1"),
                this.locale("children_2"),
                this.locale("children_3"),
            ])}`;
        },
        website: (state) => state.profile.info?.web,
        selfEsteem: (state) =>
            state.profile.info.self_esteem
                ? state.profile.info.self_esteem
                : state.locale("nothing"),
    },
    async mounted() {
        if (this.isUserAuth) {
            await this.friendsCount().then((result) => {
                this.friendsTotal = result.data.friendsCount;
            });
            await this.friendRequestsCount()
                .then((response) => {
                    this.friendRequestsCounter =
                        response.data.friendRequestsCount;
                })
                .catch((e) => {
                    throw e;
                });
        }

        await this.getPersonProfile();
    },
    methods: {
        changeAvatarCount(val) {
            this.avatarCount = val;
        },
        hideAllPhotos() {
            this.isShowAllPhotos = false;
        },
        async getPersonProfile() {
            if (!this.currentPerson.uuid) return;
            await this.$apollo
                .query({
                    query: gql(personProfileQuery),
                    variables: {
                        id: this.currentPerson.uuid,
                    },
                })
                .then((r) => {
                    this.isPersonProfileReady = true;
                    this.profile = r.data.personProfile;
                    console.log(this.profile);
                });
        },
        declination,
    },
};
</script>

<style lang="scss" scoped>
@import "./person-info.scss";
</style>

<style lang="scss">
// STYLES FOR SUBSCRIPTION
.person-info {
    .subscriptions-widget-categories {
        &__list {
            padding: 0;
        }

        &__item {
            margin-right: 8px;
            position: relative;

            &:last-child {
                margin-right: 0;
            }

            &:after {
                content: "";
                position: absolute;
                display: block;
                width: 12px;
                height: 12px;
                bottom: -6px;
                right: -6px;
                transform: rotate(45deg);
                background: #171717;
                border-left: 1px solid #393939;
            }
        }

        &-item {
            width: 42px;
            height: 42px;
            border: 1px solid #393939;
            display: flex;
            align-items: center;
            justify-content: center;

            &__icon {
                width: 26px;
                height: 26px;
            }

            &__text {
                display: none !important;
            }
        }
    }
}
</style>
