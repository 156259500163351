<template lang="pug">
    .person-mobile-header
        ._group
            ._user-type(:class="leaderTypeIcon", v-if="leaderTypeIcon")
                svg-icon._user-type-icon(:icon-name="leaderTypeIcon" addedCatalog="leader-types" v-if="leaderTypeIcon")
            ._user-info(ref="name")
                ._user-name(:class="{'is-celebrity': currentPerson.celebrity_status.celebrity}") {{ name === "Newbie" ? locale("noName") : name }}
                    svg-icon._is-celebrity(icon-name="star" v-if="currentPerson.celebrity_status.celebrity")
                    router-link._change-name-link(:to="{ name: 'change-name' }" v-if="name === 'Newbie' && isMyProfile" tag="a") {{ locale("changeName") }}
                ._user-info-leader-type
                    span(v-if="isMyProfile && !profilePreview")
                        span(v-if="leaderType === '_locked_'" @click='confirmOnBoardingSwitch("PSYCHOTIC_DETECTION")')._psychotype-locked {{ locale("my-type") }}
                        span(v-else) {{ leaderType }}
                    span(v-else) {{ leaderType === "_hidden_" || leaderType === "_locked_" ? locale("type-not-available") : leaderType }}
        person-info(:profilePreview="profilePreview")
        ._info(v-if="isCelebrity")
            ._info-group(v-if="availableSocials.length")
                a._info-item( v-for="(social, key) in socials" v-if="social && key !== '__typename'" :key="social" :href="social" target="_blank")
                    ._info-item-wrap
                        svg-icon._info-item-social-icon(:icon-name="key")
                        ._info-item-social-name(v-if="availableSocials.length - 1 <= 2") {{ socialNames[key] }}
        friend-actions(v-if="isUserAuth && !isMyProfile")._friend-actions
        another-person-actions(v-if="isUserAuth && !isMyProfile" :show-message="false")
        retake-on-boarding(v-if="isMyProfile && isUserAuth && !profilePreview")._retake
        person-mobile-business-card._business-card
        social-rating(v-if="isPersonProfileReady" :initial-rating="profile.ratings" :gender="genderLetter" :name="name")._social-rating
</template>

<script>
import FriendActions from "../../app/left-aside/friend-actions/friend-actions";
import PersonInfo from "../person-info/person-info.vue";
import SocialRating from "@/components/person/social-rating/social-rating";
import personProfileQuery from "@/graphql/queries/personProfile.query.graphql";
import gql from "graphql-tag";
import RetakeOnBoarding from "@/components/app/retake-onboarding/retake-onboarding";
import AnotherPersonActions from "@/components/person/another-person/another-person-actions/another-person-actions";
import PersonMobileBusinessCard from "@/components/person/person-mobile-business-card/person-mobile-business-card";

export default {
    name: "PersonMobileHeader",
    components: {
        PersonMobileBusinessCard,
        AnotherPersonActions,
        RetakeOnBoarding,
        SocialRating,
        FriendActions,
        PersonInfo,
    },
    props: {
        profilePreview: {
            required: false,
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            socialNames: {
                vk: "ВКонтакте",
                facebook: "Facebook",
                instagram: "Instagram",
                youtube: "YouTube",
                twitter: "Twitter",
            },
            isPersonProfileReady: false,
            profile: {
                ratings: [],
            },
        };
    },
    computed: {
        name: (state) => state.$store.getters["person/name"],
        isCelebrity: (state) => state.$store.getters["person/isCelebrity"],
        personInfo: (state) => state.$store.getters["person/info"],
        leaderType: (state) => state.$store.getters["person/leader_type"],
        leaderTypeIcon: (state) => state.typeIcons[state.leaderType],
        typeIcons: (state) => state.$store.getters["personTypeIcons"],
        genderLetter: (state) => state.personInfo.personal_data.gender,
        age: (state) =>
            state.personInfo.personal_data.date_of_birth
                ? state.calcAge(state.personInfo.personal_data.date_of_birth)
                : false,
        weight: (state) => state.personInfo.personal_data.weight,
        height: (state) => state.personInfo.personal_data.height,
        isInfoAvailable: (state) => state.age || state.weight || state.height,
        socials: (state) => state.$store.getters["person/social"],
        availableSocials: (state) =>
            Object.values(state.socials).filter((social) => social),
        uuid: (state) => state.$route.params.uuid,
    },
    watch: {
        uuid() {
            //this.getPersonProfile();
        },
    },
    mounted() {
        this.getPersonProfile();
    },
    methods: {
        async getPersonProfile() {
            this.profile = {};
            this.isPersonProfileReady = false;
            await this.$apollo
                .query({
                    query: gql(personProfileQuery),
                    variables: {
                        id: this.uuid,
                    },
                    fetchPolicy: "no-cache",
                })
                .then((r) => {
                    this.profile = r.data.personProfile;
                    setTimeout(() => {
                        this.isPersonProfileReady = true;
                    }, 400);
                })
                .catch((e) => {
                    console.log(this.$route, { e });
                });
        },
    },
};
</script>

<style src="./person-mobile-header.scss" lang="scss"></style>
