import { render, staticRenderFns } from "./person-info.vue?vue&type=template&id=b7a78082&scoped=true&lang=pug&"
import script from "./person-info.vue?vue&type=script&lang=js&"
export * from "./person-info.vue?vue&type=script&lang=js&"
import style0 from "./person-info.vue?vue&type=style&index=0&id=b7a78082&lang=scss&scoped=true&"
import style1 from "./person-info.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b7a78082",
  null
  
)

export default component.exports