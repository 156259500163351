<template lang="pug">
  .another-person-business-card
    ._item(v-if="showSection === 'all' || showSection === 'merlin'")
      ._item-title {{ locale("merlin") }}
      ._item-li
        ._item-li-circle
          svg-icon(icon-name="strengths" added-catalog="business-card")._item-li-icon
        ._item-li-content
          ._item-li-content-label {{ locale("mrln_strengths") }}
          ._item-li-content-value()
            span(v-if="person.about.mrln_strengths === '_locked_'")._blocked {{ this.locale("blocked") }}
              span._unblock(@click="openModal(2, person.about.mrln_strengths)") {{ this.locale("unlock") }}
            ._item-li-text(v-else)
              span(v-html="strengths[0]")
              span._item-more(v-if="strengths.length > 1" @click="openDescriptionModal(locale(\"mrln_strengths\"), strengths.join('. '))") {{ locale("more") }}
      ._item-li
        ._item-li-circle
          svg-icon(icon-name="talents" added-catalog="business-card")._item-li-icon
        ._item-li-content
          ._item-li-content-label {{ locale("mrln_talents") }}
          ._item-li-content-value()
            span(v-if="person.about.mrln_talents === '_locked_'")._blocked {{ this.locale("blocked") }}
              span._unblock(@click="openModal(2, person.about.mrln_talents)") {{ this.locale("unlock") }}
            ._item-li-text(v-else)
              span(v-html="talents[0]")
              span._item-more(v-if="talents.length > 1" @click="openDescriptionModal(locale(\"mrln_talents\"), talents.join('. '))") {{ locale("more") }}
      ._item-li
        ._item-li-circle
          svg-icon(icon-name="self-esteem" added-catalog="business-card")._item-li-icon
        ._item-li-content
          ._item-li-content-label {{ locale("mrln_self_esteem") }}
          ._item-li-content-value()
            span(v-if="person.about.mrln_self_esteem === '_locked_'")._blocked {{ this.locale("blocked") }}
              span._unblock(@click="openModal(2, person.about.mrln_self_esteem)") {{ this.locale("unlock") }}
            ._item-li-text(v-else)
              span(v-html="selfEsteem[0]")
              span._item-more(v-if="selfEsteem.length > 1" @click="openDescriptionModal(locale(\"mrln_self_esteem\"), selfEsteem.join('. '))") {{ locale("more") }}
      ._item-li
        ._item-li-circle
          svg-icon(icon-name="motivation" added-catalog="business-card")._item-li-icon
        ._item-li-content
          ._item-li-content-label {{ locale("mrln_motivation") }}
          ._item-li-content-value()
            span(v-if="person.about.mrln_motivation === '_locked_'")._blocked {{ this.locale("blocked") }}
              span._unblock(@click="openModal(3, person.about.mrln_motivation)") {{ this.locale("unlock") }}
            ._item-li-text(v-else)
              span(v-html="motivation[0]")
              span._item-more(v-if="motivation.length > 1" @click="openDescriptionModal(locale(\"mrln_motivation\"), motivation.join('. '))") {{ locale("more") }}
      ._item-li
        ._item-li-circle
          svg-icon(icon-name="tasks" added-catalog="business-card")._item-li-icon
        ._item-li-content
          ._item-li-content-label {{ locale("mrln_tasks") }}
          ._item-li-content-value()
            span(v-if="person.about.mrln_tasks === '_locked_'")._blocked {{ this.locale("blocked") }}
              span._unblock(@click="openModal(1, person.about.mrln_tasks)") {{ this.locale("unlock") }}
            ._item-li-text(v-else)
              span(v-html="tasks[0]")
              span._item-more(v-if="tasks.length > 1" @click="openDescriptionModal(locale(\"mrln_tasks\"), tasks.join('. '))") {{ locale("more") }}
      ._item-li(v-if="person.about.mrln_activities")
        ._item-li-circle
          svg-icon(icon-name="activities" added-catalog="business-card")._item-li-icon
        ._item-li-content
          ._item-li-content-label {{ locale("mrln_activities") }}
          ._item-li-content-value()
            span(v-if="person.about.mrln_activities === '_locked_'")._blocked {{ this.locale("blocked") }}
              span._unblock(@click="openModal(1, person.about.mrln_activities)") {{ this.locale("unlock") }}
            ._item-li-text(v-else)
              span(v-html="activities[0]")
              span._item-more(v-if="activities.length > 1" @click="openDescriptionModal(locale(\"mrln_activities\"), activities.join('. '))") {{ locale("more") }}
    ._item(v-if="showSection === 'all' || showSection === 'user'")
      ._item-title {{ locale("aboutMe", {name: name}) }}
      ._item-li(v-if="isMobileView")
        ._item-li-circle
          svg-icon(icon-name="self-esteem" added-catalog="business-card")._item-li-icon
        ._item-li-content
          ._item-li-content-label {{ locale("self_esteem") }}
          ._item-li-content-value(@click="editAboutInfo(true)" :class="{pointer: isMyProfile}") {{ person.info.self_esteem || locale("self_esteemUndefined") }}
              svg-icon(icon-name="pencil" v-if="isMyProfile")._item-li-edit-icon
      ._item-li
        ._item-li-circle
          svg-icon(icon-name="like" added-catalog="business-card")._item-li-icon
        ._item-li-content
          ._item-li-content-label {{ locale("user_like", {name}) }}
          ._item-li-content-value(v-if="isUserAuth" @click="editAboutInfo" :class="{pointer: isMyProfile}") {{ person.about.user_like || locale("user_likeUndefined") }}
              svg-icon(icon-name="pencil" v-if="isMyProfile")._item-li-edit-icon
          ._item-li-content-value(v-else) {{ this.locale("blocked") }}
      ._item-li
        ._item-li-circle
          svg-icon(icon-name="good" added-catalog="business-card")._item-li-icon
        ._item-li-content
          ._item-li-content-label {{ locale("user_good", {name}) }}
          ._item-li-content-value(v-if="isUserAuth" @click="editAboutInfo" :class="{pointer: isMyProfile}") {{ person.about.user_good || locale("user_goodUndefined") }}
              svg-icon(icon-name="pencil" v-if="isMyProfile")._item-li-edit-icon
          ._item-li-content-value(v-else) {{ this.locale("blocked") }}
      ._item-li
        ._item-li-circle
          svg-icon(icon-name="usually" added-catalog="business-card")._item-li-icon
        ._item-li-content
          ._item-li-content-label {{ locale("user_usually", {name}) }}
          ._item-li-content-value(v-if="isUserAuth" @click="editAboutInfo" :class="{pointer: isMyProfile}") {{ person.about.user_usually || locale("user_usuallyUndefined") }}
              svg-icon(icon-name="pencil" v-if="isMyProfile")._item-li-edit-icon
          ._item-li-content-value(v-else) {{ this.locale("blocked") }}
      ._item-li
        ._item-li-circle
          svg-icon(icon-name="work" added-catalog="business-card")._item-li-icon
        ._item-li-content
          ._item-li-content-label {{ locale("user_work", {name}) }}
          ._item-li-content-value(v-if="isUserAuth" @click="editAboutInfo" :class="{pointer: isMyProfile}") {{ person.about.user_work || locale("user_workUndefined") }}
              svg-icon(icon-name="pencil" v-if="isMyProfile")._item-li-edit-icon
          ._item-li-content-value(v-else) {{ this.locale("blocked") }}
      ._item-li
        ._item-li-circle
          svg-icon(icon-name="goal" added-catalog="business-card")._item-li-icon
        ._item-li-content
          ._item-li-content-label {{ locale("user_goal", {name}) }}
          ._item-li-content-value(v-if="isUserAuth" @click="editAboutInfo" :class="{pointer: isMyProfile}") {{ person.about.user_goal || locale("user_goalUndefined") }}
              svg-icon(icon-name="pencil" v-if="isMyProfile")._item-li-edit-icon
          ._item-li-content-value(v-else) {{ this.locale("blocked") }}
      ._item-li
        ._item-li-circle
          svg-icon(icon-name="skill" added-catalog="business-card")._item-li-icon
        ._item-li-content
          ._item-li-content-label {{ locale("user_skill", {name}) }}
          ._item-li-content-value(v-if="isUserAuth" @click="editAboutInfo" :class="{pointer: isMyProfile}") {{ person.about.user_skill || locale("user_skillUndefined") }}
              svg-icon(icon-name="pencil" v-if="isMyProfile")._item-li-edit-icon
          ._item-li-content-value(v-else) {{ this.locale("blocked") }}
    ._item(v-if="person.careers.length && (showSection === 'all' || showSection === 'career')")
      ._item-title {{ locale("career") }}
      ._item-wrap(v-for="career in person.careers" :key="career.id")
        ._item-wrap-title {{ career.position }}
        ._item-wrap-value {{ career.company }}
        ._item-wrap-label {{ formatDate(career) }}
    ._item(v-if="person.educations.length && (showSection === 'all' || showSection === 'education')")
      ._item-title {{ locale("education") }}
      ._item-wrap(v-for="education in person.educations" :key="education.id")
        ._item-wrap-title {{ education.institution }}
        ._item-wrap-value {{ education.speciality }}
        ._item-wrap-label {{ formatDate(education) }}
</template>

<script>
import DescriptionPopup from "@/components/app/description-popup/description-popup";
export default {
    name: "AnotherPersonBusinessCard",
    components: { DescriptionPopup },
    props: {
        person: {
            type: Object,
            required: true,
            default: () => {},
        },
        showSection: {
            type: String,
            default: "all",
            required: false,
        },
    },
    data() {
        return {
            categoryId: 0,
        };
    },
    computed: {
        name: (state) => state.$store.getters["person/name"],
        strengths: function () {
            return this.formatText(this.person.about.mrln_strengths);
        },
        talents: function () {
            return this.formatText(this.person.about.mrln_talents);
        },
        selfEsteem: function () {
            return this.formatText(this.person.about.mrln_self_esteem);
        },
        motivation: function () {
            return this.formatText(this.person.about.mrln_motivation);
        },
        tasks: function () {
            return this.formatText(this.person.about.mrln_tasks);
        },
        activities: function () {
            return this.formatText(this.person.about.mrln_activities);
        },
    },
    methods: {
        formatDate(career) {
            const start = new Date(career.start_at).getFullYear();
            const end = career.end_at
                ? new Date(career.end_at).getFullYear()
                : this.locale("fromNow");

            return `${start} — ${end}`;
        },
        formatText(text) {
            let $text = text.replace(/\n/, "<br/>").split(".");

            if ($text.length > 1) {
                $text[0] = $text[0] + ".";
            }

            return $text;
        },
        openModal(id, text) {
            if (!this.isAuth()) return;

            if (text !== "_locked_") return;
            this.categoryId = id;
            let category = "";
            if (id === 1) {
                category = this.locale("categoryProfession");
            }
            if (id === 2) {
                category = this.locale("categorySelfEsteem");
            }
            if (id === 3) {
                category = this.locale("categoryMotivation");
            }
            this.$store.dispatch("changeConfirmDialog", {
                show: true,
                loading: false,
                title: this.locale("needSubscribe"),
                text: this.locale("needSubscribeText", {category}),
                buttons: true,
                confirmText: this.locale("subscribe"),
                callback: this.showModal,
            });
        },
        showModal() {
            this.$modal.show("subscription", { categoryId: this.categoryId });
        },
        openDescriptionModal(title, text) {
            console.log(title, text);
            this.$bus.$emit("showPersonDescriptionModal", title, text);
        },
        editAboutInfo(isSelfEsteem = false) {
            if (isSelfEsteem) {
                return this.$router.push("/settings/added-info");
            }
            this.$router.push("/settings/about");
        },
    },
};
</script>

<style lang="scss" scoped>
@import "./another-person-business-card.scss";
</style>
