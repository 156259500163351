<template lang="pug">
  .another-person-actions
    ui-button._btn(@click.native="sendMessage('')" type="bordered", color="gray", :class="{'another-person-actions__btn-disabled': !chatAvailable}" v-if="showMessage")
      template(v-slot:content)
        svg-icon._btn-icon(iconName="message")
        span._btn-text {{ locale("sendMessage") }}
    ui-button._btn(@click.native="sendMessage('REACH_THE_GOAL')" type="bordered", color="gray", :class="{'another-person-actions__btn-disabled': !chatAvailable}")
      template(v-slot:content)
        svg-icon._btn-icon(iconName="target", class="target")
        span._btn-text {{ locale("sendTarget") }}
    ui-button._btn(@click.native="sendMessage('BUSINESS_PROPOSAL')" type="bordered", color="gray", :class="{'another-person-actions__btn-disabled': !professionAvailable}")
      template(v-slot:content)
        svg-icon._btn-icon(iconName="handshake", class="handshake")
        span._btn-text {{ locale("sendBusiness") }}
    //ui-button._btn(type="bordered", color="gray", :disabled="true", class="another-person-actions__btn-disabled")
    //  template(v-slot:content)
    //    svg-icon._btn-icon(iconName="lock", class="lock")
    //    //- svg-icon._btn-icon(iconName="likeness", class="likeness")
    //    span._btn-text ПОСОВЕТОВАТЬ КОМУ-ТО
</template>

<script>
import UiButton from "@/components/ui/ui-button/ui-button";
import FriendActions from "@/components/app/left-aside/friend-actions/friend-actions";
import gql from "graphql-tag";
import friendChatAvailabilityCheckQuery from "@/graphql/queries/friendChatAvailabilityCheck.query.graphql";
import friendingStatusQuery from "@/graphql/queries/friendingStatus.query.graphql";
import friendRequestQuery from "@/graphql/queries/friendRequest.query.graphql";

export default {
    name: "AnotherPersonActions",
    components: { FriendActions, UiButton },
    props: {
        showMessage: {
            type: Boolean,
            default: true,
            required: false,
        },
    },
    data() {
        return {
            isChatAvailability: false,
            userFriendingStatus: "",
        };
    },
    computed: {
        personUuid: (state) => state.currentPerson.uuid,
        userUuid: (state) => state.currentPerson.assigned_user,
        chatAvailable: function () {
            return (
                this.isUserAuth &&
                (this.userFriendingStatus === "ADDED" ||
                    this.isChatAvailability)
            );
        },
        subscriptions: (state) =>
            state.$store.getters["user/subscriptionCategories"],
        professionAvailable: (state) =>
            state.subscriptions.some(
                (i) => [1, 2, 9, 10].indexOf(Number(i.planId)) >= 0,
            ),
    },
    created() {
        if (this.isUserAuth) {
            this.chatAvailableCheck();
            this.checkUserFriendingStatus();
        }
    },
    methods: {
        async chatAvailableCheck() {
            await this.$apollo
                .query({
                    query: gql`
                        ${friendChatAvailabilityCheckQuery}
                    `,
                    variables: {
                        uuid: this.currentPerson.assigned_user,
                    },
                })
                .then((r) => {
                    this.isChatAvailability =
                        r.data.friendChatAvailabilityCheck;
                });
        },
        async checkUserFriendingStatus() {
            await this.$apollo
                .query({
                    query: gql`
                        ${friendingStatusQuery}
                    `,
                    variables: {
                        id: this.personUuid,
                    },
                    fetchPolicy: "no-cache",
                })
                .then((response) => {
                    console.log(response);
                    this.userFriendingStatus = response.data.friendingStatus;
                })
                .catch((e) => {
                    console.log(e);
                });
        },
        sendMessage(type) {
            if (!this.isChatAvailability) {
                this.$store.dispatch("changeConfirmDialog", {
                    show: true,
                    loading: false,
                    title: this.locale("targetConfirmTitle"),
                    text: this.locale("targetConfirmText"),
                    buttons: true,
                    confirmText: this.locale("add"),
                    callback: this.addToFriends,
                });
                return;
            }

            if (type === "BUSINESS_PROPOSAL" && !this.professionAvailable) {
                this.$store.dispatch("changeConfirmDialog", {
                    show: true,
                    loading: false,
                    title: this.locale("targetConfirmTitle"),
                    text: this.locale("businessConfirmText"),
                    buttons: true,
                    confirmText: this.locale("subscribe"),
                    callback: this.showSubscribeModal,
                });
                return;
            }
            this.$router.push(`/messages/${this.currentPerson.assigned_user}/${type}`);
        },
        async addToFriends() {
            await this.$apollo
                .query({
                    query: gql`
                        ${friendRequestQuery}
                    `,
                    variables: {
                        id: this.userUuid,
                    },
                    fetchPolicy: "no-cache",
                })
                .then(() => {
                    this.$emit("friend-request");
                    this.checkUserFriendingStatus();
                })
                .catch((e) => {
                    let title = "unknownError",
                        text = "retry",
                        type = "";
                    if (e.graphQLErrors) {
                        const codeError = e.graphQLErrors[0].extensions.code;
                        switch (codeError) {
                            case 1001:
                                this.$store.dispatch("auth/signout");
                                break;

                            case 1002:
                                title = "error";
                                text = "pageError";
                                break;

                            case 1006:
                                title = "unknownError";
                                text = "friendRequestError";
                                break;

                            case 1007:
                                title = "alreadyFriendsTitle";
                                text = "alreadyFriends";
                                type = "info";
                                break;

                            case 1502:
                                title = "info";
                                text = "alreadySendFriendRequest";
                                type = "info";
                                break;

                            case 1503:
                                title = "info";
                                text = "friendRequestDecline";
                                break;

                            default:
                                title = "unknownError";
                                text = "retry";
                        }
                    }
                    this.$msgBox(
                        this.localeError(title),
                        this.localeError(text),
                        type,
                    );
                });
        },
        showSubscribeModal() {
            this.$modal.show("subscription", { categoryId: 1 });
        },
    },
};
</script>

<style lang="scss" scoped>
@import "./another-person-actions.scss";
</style>
