<template lang="pug">
    .content__main.-without-right-aside
        Loading(v-if="loading")
        another-person(v-else-if="!isMobileView")
        person(v-else-if="isMobileView")
</template>

<script>
import person from "../components/person/person";
import AnotherPerson from "../components/person/another-person/another-person.vue";
import gql from "graphql-tag";
import getPersonQuery from "@/graphql/queries/getPerson.query.graphql";

export default {
    name: "Person",
    components: { person, AnotherPerson },
    data: () => ({
        loading: true,
        changePublicUrl: false,
    }),
    metaInfo() {
        return {
            title: `${this.personName} - подробный анализ личности человека искусственным интеллектом`,
            meta: [
                {
                    name: "description",
                    content: `На странице подробно описан характер, лидерские качества, темперамент, тип мышления личности ${this.personName}. Также можно узнать свою похожесть и совместимость с ним`,
                    vmid: "description",
                },
                {
                    name: "keywords",
                    content: `${this.firstName}${this.lastName}, характер, темперамент, мышление, совместимость, похожесть`,
                    vmid: "keywords",
                },
                {
                    property: "og:description",
                    content:
                        "На странице подробно описан характер, лидерские качества, темперамент, тип мышления личности ${this.personName}. Также можно узнать свою похожесть и совместимость с ним",
                    vmid: "og_description",
                },
                {
                    property: "og:title",
                    content: `${this.personName} - подробный анализ личности человека искусственным интеллектом`,
                    vmid: "og_title",
                },
                {
                    property: "og:image",
                    content: this.personAvatar ? this.personAvatar : "",
                    vmid: "og_image",
                },
            ],
        };
    },
    computed: {
        personName: (state) => state.$store.getters["person/name"],
        splitName: (state) => state.personName.split(" "),
        firstName: (state) => state.splitName[0],
        lastName: (state) =>
            state.splitName.length > 1 ? `, ${state.splitName[1]}` : "",
        personAvatar: (state) => state.$store.getters["person/photos"].avatar,
        profileData: (state) => state.$store.getters["person/info"],
    },
    watch: {
        $route: function () {
            if (!this.changePublicUrl) {
                this.fetchPersonInfo();
            }
            this.changePublicUrl = false;
        },
    },
    async mounted() {
        await this.$store.dispatch("setPage", "person");
        await this.fetchPersonInfo();
    },
    methods: {
        showNextTip(skippedTips, nextTip) {
            if (nextTip === undefined) return;
            if (skippedTips.indexOf(nextTip.name) === -1) {
                this.showTips(nextTip.name);
                return;
            }

            const next = this.tips.find((tip) => tip.prev === nextTip.name);
            this.showNextTip(skippedTips, next);
        },
        async fetchPersonInfo() {
            let personUuid = this.$route.params.uuid;

            // Запрашиваем инфу о личности
            await this.$apollo
                .query({
                    query: gql(getPersonQuery),
                    variables: {
                        uuid: personUuid,
                    },
                })
                .then(async (r) => {
                    await this.$store.dispatch(
                        "person/setPerson",
                        r.data.getPerson,
                    );

                    const publicUrl = this.$store.getters["person/url"];
                    if (publicUrl && this.$route.params.uuid !== publicUrl) {
                        this.changePublicUrl = true;
                        await this.$router.replace({
                            name: "public_person",
                            params: { uuid: publicUrl },
                        });
                    }

                    this.loading = false;

                    // @TODO: Подсказки выключены, т.к. их нужно адаптировать под новый интерфейс
                    // setTimeout(() => {
                    //     if (
                    //         this.isMyProfile &&
                    //         this.userExternalIdPresent
                    //     ) {
                    //         if (this.personName === "Newbie") {
                    //             this.showTips("set-name");
                    //         } else if (
                    //             this.personName !== "Newbie" &&
                    //             this.personAvatar === null
                    //         ) {
                    //             this.showTips("avatar");
                    //         } else {
                    //             const tips = localStorage.getItem("tips");
                    //
                    //             if (tips === "all") return;
                    //             const skippedTips = JSON.parse(tips) || [];
                    //             const nextTip = this.tips.find(
                    //                 (tip) => tip.name === "recommendations",
                    //             );
                    //
                    //             this.showNextTip(skippedTips, nextTip);
                    //         }
                    //     }
                    // }, 200);
                })
                .catch((e) => {
                    console.log("get person error", e);
                    if (e.graphQLErrors) {
                        const error = e.graphQLErrors[0].extensions.code;
                        switch (error) {
                            case 1001:
                                this.$store.dispatch("auth/signout");
                                break;
                            case 1002:
                                this.$router.push({ name: "error-404" });
                                break;
                            case 1003:
                                localStorage.redirect = this.$route.fullPath;
                                this.$router.push({ name: "signin" });
                                this.$msgBox(
                                    "Ошибка",
                                    "Для просмотра этой личности требуется авторизация",
                                    "error",
                                );
                                break;
                            default:
                                this.$msgBox(error, "Личность не найдена");
                                break;
                        }
                    }
                });
        },
    },
};
</script>
