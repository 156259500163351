<template lang="pug">
    .legal-links.-mobile
        lang-switcher
        ._item(@click="showModal('referral')") {{ locale("referral") }}
        ._item.-toggle(@click="legalToggleActive = !legalToggleActive" :class="{active: legalToggleActive}") {{ locale("legal-title") }}
            svg-icon(icon-name="chevron-down")._item-icon
        ._toggle(v-if="legalToggleActive")
            router-link(:to="{name: 'Static', params: { page: 'requisites'}}")._item {{ locale("requisites") }}
            ._group
                router-link(:to="{name: 'Static', params: { page: 'about'}}")._item {{ locale("about") }}
                router-link(:to="{name: 'Static', params: { page: 'payments'}}")._item {{ locale("payments") }}
            router-link(:to="{name: 'Static', params: { page: 'term-of-use'}}")._item {{ locale("term-of-use") }}
            a._item(href="/docs/privacy-policy.html") {{ locale("privacy-policy") }}
        a(:href="SUPPORT_LINK" target="_blank")._item
            svg-icon(icon-name="telegram")._item-icon.-telegram
            | {{ locale("support") }}
        ._group
            ._item(v-if="isMyProfile" @click="showModal('payout')") {{ locale("payout") }}
            ._item(v-if="isMyProfile" @click="logout()") {{ locale("logout", {} , true) }}

</template>

<script>
import LangSwitcher from "@/components/app/lang-switcher/lang-switcher.vue";

export default {
    name: "LegalLinksMobile",
    components: { LangSwitcher },
    data() {
        return {
            legalToggleActive: false,
        };
    },
    methods: {
        showModal(name) {
            this.$modal.show(name, { props: { allowComponent: true } });
        },
        async logout() {
            await this.$store.dispatch("auth/signout");
            await this.$router.push("/");
            this.$router.go();
        },
    },
};
</script>

<style lang="scss" src="./legal-links.scss"></style>
