<template lang="pug">
  .mobile-avatar-gallery
    change-photos(:show-gift="false" photoType="avatar" :success-text="locale('photo-uploaded')" @photo-uploaded="getGallery" v-if="isMyProfile && showAll")
    ._photo-box(v-if="!gallery.length && !showAll")
      user-photo(:photo="avatar" :micro="true")
    ._photo-box(v-if="index === 0 || showAll" v-for="(item, index) in gallery")
      user-photo(:photo="item.photo" :micro="false")._photo
      ._remove-photo(@click="confirmDeletePhoto(item)" v-if="showAll && item.allow_delete")
        svg-icon(icon-name="cross")._remove-icon
      ._set-photo(v-if="showAll && item.allow_delete" @click="setAvatar(item.id)") {{ locale("choose-main") }}
</template>

<script>
import UserPhoto from "@/components/ui/user-photo/user-photo";
import getPhotoGalleryQuery from "@/graphql/queries/getPhotoGallery.query.graphql";
import deletePhotoFromGalleryMutation from "@/graphql/mutations/deletePhotoFromGallery.mutation.graphql";
import gql from "graphql-tag";
import ChangePhotos from "@/components/person/change-photos/change-photos";
import setAvatarFromGalleryMutation from "@/graphql/mutations/setAvatarFromGallery.mutation.graphql";

export default {
    name: "MobileAvatarGallery",
    components: { ChangePhotos, UserPhoto },
    props: {
        showAll: {
            type: Boolean,
            default: false,
            required: true,
        },
    },
    data() {
        return {
            gallery: [],
            photoToDelete: null,
        };
    },
    computed: {
        avatar: (state) => state.$store.getters["person/photos"].avatar,
    },
    mounted() {
        this.getGallery();
    },
    methods: {
        async getGallery() {
            await this.$apollo
                .query({
                    query: gql(getPhotoGalleryQuery),
                    fetchPolicy: "no-cache",
                })
                .then((r) => {
                    const gallery = r.data.getPhotoGallery;

                    this.$emit("change-avatar-count", gallery.length);

                    if (!gallery.length) {
                        this.gallery = [];
                        return;
                    }
                    const active = gallery.find((p) => !p.allow_delete);
                    gallery.splice(gallery.indexOf(active), 1);
                    gallery.unshift(active);
                    this.gallery = gallery;
                });
        },
        confirmDeletePhoto(photo) {
            this.photoToDelete = photo;
            this.$store.dispatch("changeConfirmDialog", {
                show: true,
                loading: false,
                title: this.locale("confirmDeleteTitle"),
                text: this.locale("confirmDeleteText"),
                buttons: true,
                confirmText: this.locale("confirmDeleteBtn"),
                callback: this.deletePhoto,
            });
        },
        async deletePhoto() {
            await this.$apollo
                .mutate({
                    mutation: gql(deletePhotoFromGalleryMutation),
                    variables: {
                        id: this.photoToDelete.id,
                    },
                })
                .then(() => {
                    this.getGallery();
                    this.$emit("photo-deleted");
                });
        },
        async setAvatar(id) {
            await this.$apollo
                .mutate({
                    mutation: gql(setAvatarFromGalleryMutation),
                    variables: {
                        id: id,
                    },
                })
                .then(() => {
                    this.getGallery();
                });
        },
    },
};
</script>

<style lang="scss" src="./mobile-avatar-gallery.scss"></style>
