<template lang="pug">
    .user-recommendations-mobile(data-tip="people-recommendations")
        perfect-scrollbar._wrap
            ._list(v-if="recommendedPeoples.length")
                router-link._user(v-for="person in recommendedPeoples" :key="person.user_id" :to="{name: 'person', params: { uuid: person.person_id }}")
                    ._user-photo
                        img(v-if="person.photo" :src="person.photo")._user-avatar
                        ._no-photo(v-else)
                            svg-icon(icon-name="no-avatar")._no-photo-icon
                        ._type(v-if="person.category" @click.prevent)
                            svg-icon(:icon-name="getIcon(person.category)")._type-icon
                            ._type-name(v-html="typeNames[person.category.toUpperCase()]")
                    ._user-name {{ person.name ? person.name : locale("newbie", {}, true) }}
            ._no-results(v-else) {{ locale("analyze") }}
</template>

<script>
import gql from "graphql-tag";
import myRecommendedPeopleQuery from "@/graphql/queries/myRecommendedPeople.query.graphql";

export default {
    name: "UserRecommendationsMobile",
    data() {
        return {
            recommendedPeoples: [],
            hasMore: false,
            page: 1,
            itemsPerPage: 10,
            categories: {
                "-1": "ALL",
                0: "PROFESSIONAL",
                1: "RELATIONS",
                2: "MOTIVATIONS",
                3: "SELFASSESSMENT",
            },
            icons: {
                PROFESSIONAL: "sub-cat-prof",
                RELATIONS: "sub-cat-relations",
                MOTIVATION: "sub-cat-motivation",
                SELFASSESSMENT: "sub-cat-self-esteem",
            },
            typeNames: {
                PROFESSIONAL: this.locale("category_1"),
                RELATIONS: this.locale("category_2"),
                MOTIVATION: this.locale("category_3"),
                SELFASSESSMENT: this.locale("category_4"),
            },
        };
    },
    computed: {
        activeSectionId: (state) =>
            state.$store.getters["descriptionSectionId"],
    },
    watch: {
        activeSectionId() {
            this.getRecommendedPeoples();
        },
        recommendedPeoples(list) {
            // +1 добавлено временно, чтобы показывать верно подсказку
            this.$emit("changeVisible", list.length + 1);
        },
    },
    created() {
        this.getRecommendedPeoples();
    },
    methods: {
        async getRecommendedPeoples() {
            await this.$apollo
                .query({
                    query: gql`
                        ${myRecommendedPeopleQuery}
                    `,
                    variables: {
                        category: this.categories[this.activeSectionId],
                        page: this.page,
                        itemsPerPage: this.itemsPerPage,
                    },
                })
                .then((r) => {
                    const result = r.data.myRecommendedPeople;
                    this.recommendedPeoples = result.list;
                    this.hasMore = result.hasMore;
                    if (result.hasMore) {
                        this.page++;
                    }
                });
        },
        getIcon(type) {
            return this.icons[type.toUpperCase()];
        },
    },
};
</script>

<style src="./user-recommendations-mobile.scss" lang="scss" scoped></style>
