<template lang="pug">
    .social-rating
        template(v-if="$i18n.locale === 'ru'")
            ._title(v-if="isMobileView && name !== 'Newbie'") {{ locale("start", {suffix}) }}
                span(v-if="isMyProfile")  {{ locale("end") }}
                span(v-else)  {{ name }}:
        template(v-if="$i18n.locale === 'en'")
            ._title(v-if="isMobileView && name !== 'Newbie'")
                span(v-if="isMyProfile")  {{ locale("start") }}
                span(v-else) {{ locale("end", {name}) }}:
        ._item(v-for="item in list" @click="setRating(item)") {{ item.text }}
            span._item-count {{ item.points }}
</template>

<script>
import gql from "graphql-tag";
import personSetRatingMutation from "@/graphql/mutations/personSetRating.mutation.graphql";

export default {
    name: "SocialRating",
    props: {
        initialRating: {
            type: Array,
            required: true,
            default: () => [],
        },
        gender: {
            type: String,
            default: "",
            required: false,
        },
        name: {
            type: String,
            default: "",
            required: false,
        },
    },
    data() {
        return {
            advantages: {
                ru: [
                    { id: 0, text: "👌 Надежный", points: 0 },
                    { id: 1, text: "👱 Влиятельный", points: 0 },
                    { id: 2, text: "🧐 Изобретательный", points: 0 },
                    { id: 3, text: "🤩 Известный", points: 0 },
                    { id: 4, text: "🤝 Дипломатичный", points: 0 },
                    { id: 5, text: "😇 Альтруист", points: 0 },
                    { id: 6, text: "🥳 Активный", points: 0 },
                    { id: 7, text: "💰 Успешный", points: 0 },
                    { id: 8, text: "🤓 Умный", points: 0 },
                    { id: 9, text: "🔧 Работящий", points: 0 },
                ],
                en: [
                    { id: 0, text: "👌 Reliable", points: 0 },
                    { id: 1, text: "👱 Influential", points: 0 },
                    { id: 2, text: "🧐 Resourceful", points: 0 },
                    { id: 3, text: "🤩 Famous", points: 0 },
                    { id: 4, text: "🤝 Diplomatic", points: 0 },
                    { id: 5, text: "😇 Altruistic", points: 0 },
                    { id: 6, text: "🥳 Active", points: 0 },
                    { id: 7, text: "💰 Successful", points: 0 },
                    { id: 8, text: "🤓 Smart", points: 0 },
                    { id: 9, text: "🔧 Working", points: 0 },
                ],
                kg: [
                    { id: 0, text: "👌 Ишенимдүү", points: 0 },
                    { id: 1, text: "👱 таасирдүү'sirli", points: 0 },
                    { id: 2, text: "🧐 тапкыч", points: 0 },
                    { id: 3, text: "🤩 Famous", points: 0 },
                    { id: 4, text: "🤝 Дипломатиялык", points: 0 },
                    { id: 5, text: "😇 Альтруисттик", points: 0 },
                    { id: 6, text: "🥳 Активдүү", points: 0 },
                    { id: 7, text: "💰 Ийгиликтүү", points: 0 },
                    { id: 8, text: "🤓 Акылдуу", points: 0 },
                    { id: 9, text: "🔧 Иштеп жатат", points: 0 },
                ],
                uz: [
                    { id: 0, text: "👌 Ishonchli", points: 0 },
                    { id: 1, text: "👱 Ta'sirli", points: 0 },
                    { id: 2, text: "🧐 Topqir", points: 0 },
                    { id: 3, text: "🤩 Mashhur", points: 0 },
                    { id: 4, text: "🤝 Diplomatik", points: 0 },
                    { id: 5, text: "😇 altruistik", points: 0 },
                    { id: 6, text: "🥳 Faol", points: 0 },
                    { id: 7, text: "💰 Omadli", points: 0 },
                    { id: 8, text: "🤓 Aqlli", points: 0 },
                    { id: 9, text: "🔧 Ishlash", points: 0 },
                ],
                kz: [
                    { id: 0, text: "👌 Сенімді", points: 0 },
                    { id: 1, text: "👱 Әсерлі", points: 0 },
                    { id: 2, text: "🧐 Тапқыр", points: 0 },
                    { id: 3, text: "🤩 Атақты", points: 0 },
                    { id: 4, text: "🤝 Дипломатиялық", points: 0 },
                    { id: 5, text: "😇 Альтруистік", points: 0 },
                    { id: 6, text: "🥳 Белсенді", points: 0 },
                    { id: 7, text: "💰 Сәтті", points: 0 },
                    { id: 8, text: "🤓 Ақылды", points: 0 },
                    { id: 9, text: "🔧 Жұмыс істеу", points: 0 },
                ],
                tj: [
                    { id: 0, text: "👌 Эътимоднок", points: 0 },
                    { id: 1, text: "👱 таъсирбахш", points: 0 },
                    { id: 2, text: "🧐 Боистеъдод", points: 0 },
                    { id: 3, text: "🤩 машхур", points: 0 },
                    { id: 4, text: "🤝 Дипломатӣ", points: 0 },
                    { id: 5, text: "😇 Алтруистӣ", points: 0 },
                    { id: 6, text: "🥳 Фаъол", points: 0 },
                    { id: 7, text: "💰 Муваффақ", points: 0 },
                    { id: 8, text: "🤓 Ақл", points: 0 },
                    { id: 9, text: "🔧 Кор кардан", points: 0 },
                ],
                az: [
                    { id: 0, text: "👌 Etibarlı", points: 0 },
                    { id: 1, text: "👱 Təsirli", points: 0 },
                    { id: 2, text: "🧐 Bacarıqlı", points: 0 },
                    { id: 3, text: "🤩 məşhur", points: 0 },
                    { id: 4, text: "🤝 Diplomatik", points: 0 },
                    { id: 5, text: "😇 fədakar", points: 0 },
                    { id: 6, text: "🥳 Aktiv", points: 0 },
                    { id: 7, text: "💰 Uğurlu", points: 0 },
                    { id: 8, text: "🤓 Ağıllı", points: 0 },
                    { id: 9, text: "🔧 İşləyir", points: 0 },
                ],
            },
        };
    },
    computed: {
        list: state => state.advantages[state.$i18n.locale],
        suffix: (state) =>
            state.currentPerson.personal_data.gender === "M" ? "ой" : "ая",
    },
    created() {
        this.list.forEach((rating) => {
            if (this.currentPerson.personal_data.gender === "F") {
                rating.text = rating.text
                    .replace("ый", "ая")
                    .replace("ий", "ая");
            }
        });
        this.initialRating.forEach((rating) => {
            this.setItem(rating);
        });
    },
    methods: {
        setRating(category) {
            if(!this.isUserAuth) {
                return this.$msgBox(
                    "Ошибка",
                    "Для совершения данного действия требуется авторизация",
                );
            }
            this.$apollo
                .mutate({
                    mutation: gql(personSetRatingMutation),
                    variables: {
                        personId: this.currentPerson.uuid,
                        categoryId: category.id,
                    },
                })
                .then((r) => {
                    this.resetPoints();
                    r.data.personSetRating.forEach((rating) => {
                        this.setItem(rating);
                    });
                });
        },
        setItem(rating) {
            const category = this.list.find((i) => {
                return i.id === rating.id;
            });

            if (category?.text) {
                console.log(rating.points);
                category.points = rating.points;
            }
        },
        resetPoints() {
            this.list.forEach((i) => (i.points = 0));
        },
    },
};
</script>

<style lang="scss" src="./social-rating.scss"></style>
