<template lang="pug">
    .person-mobile-business-card-preview
        ._heading
            ._heading-text(v-html="locale('title')")
            ._heading-btn(@click="$emit('close')")
                svg-icon(iconName="close")
        ._content
            person-mobile-header(v-if="isMobileView", :profilePreview="true")._no-events
            person-mobile-business-card(:profilePreview="true")
            //available-social
            ._sharing
              ui-button(v-if="!showSharing" color="gold" size="full" @click.native="showSharing = true")
                  template(v-slot:content) {{ locale("send") }}
              right-aside-social(v-else)
</template>

<script>
import PersonMobileHeader from "../person-mobile-header/person-mobile-header";
import PersonMobileBusinessCard from "@/components/person/person-mobile-business-card/person-mobile-business-card.vue";
import AvailableSocial from "@/components/person/available-social/available-social";
import UiButton from "@/components/ui/ui-button/ui-button";
import RightAsideSocial from "@/components/app/right-aside/social/social";

export default {
    name: "PersonMobileBusinessCardPreview",
    components: {
        RightAsideSocial,
        PersonMobileHeader,
        PersonMobileBusinessCard,
        AvailableSocial,
        UiButton,
    },
    data() {
        return {
            showSharing: false,
        };
    },
};
</script>

<style lang="scss">
@import "./person-mobile-business-card-preview.scss";
</style>
