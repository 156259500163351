<template lang="pug">
    .person-compare-mobile(:class="view")
        perfect-scrollbar._wrap
            ._list
                ._item(v-for="compare in serviceTypes" v-if="compare.type === 'compare' && (type === 'compare') && compare.categories.indexOf(category) >= 0" :class="cardClass(compare.type)" :key="compare.id" @click="getCompare(compare)")
                    ._item-icon-box
                        svg-icon(:icon-name="compare.icon" :class="compare.icon")._item-icon
                    ._item-name {{ $t(`serviceTypes.${compare.id}`) }}
                ._item(v-for="indicator in serviceTypes" v-if="indicator.type === 'indicator' && (type === 'indicator') && indicator.categories.indexOf(category) >= 0" :class="cardClass(indicator.type)" :key="indicator.id" @click="getIndicator(indicator)")
                    ._item-icon-box
                        svg-icon(:icon-name="indicator.icon" :class="indicator.icon")._item-icon
                    ._item-name {{ isMyProfile ? $t(`serviceTypes.${indicator.id}_MY`) : $t(`serviceTypes.${indicator.id}_OTHER`) }}
                    element-decor(size="small")
                ._item(v-for="aggression in serviceTypes" v-if="aggression.type === 'aggression' && (type === 'aggression') && aggression.categories.indexOf(category) >= 0 && aggression.id !== 'INTELLIGENCE_LEVEL'" :class="cardClass(aggression.type)" :key="aggression.id" @click="getIndicator(aggression)")
                    ._item-icon-box
                        svg-icon(:icon-name="aggression.icon" :class="aggression.icon")._item-icon
                    ._item-name {{ $t(`serviceTypes.${aggression.id}`) }}
                    element-decor(design="gray" size="small")

</template>

<script>
import { compareMixins } from "@/mixins/compare.mixins";

export default {
    name: "PersonCompareMobile",
    mixins: [compareMixins],
    props: {
        type: {
            type: String,
            required: true,
            default: "",
        },
        view: {
            type: String,
            required: false,
            default: "",
        },
        category: {
            type: Number,
            required: true,
            default: 0
        },
    },
    data: () => ({
        showCompares: false,
        showIndicators: false,
        showAggressions: false,
        activeType: {},
    }),
    computed: {
        personUuid: (state) => state.$store.getters["person/uuid"],
        personName: (state) => state.$store.getters["person/name"],
        personAvatar: (state) => state.$store.getters["person/avatar"],
        externalId: (state) =>
            state.manualExternalId !== ""
                ? state.manualExternalId
                : state.$store.getters["person/externalIdPresent"],
        compareTitle: (state) =>
            state.myProfile
                ? state.locale("compare.myProfile")
                : state.locale("compare.withUser", {user: state.personName}),
        indicatorTitle: (state) =>
            state.myProfile
                ? state.locale("indicator.myProfile")
                : state.locale("indicator.withUser", {user: state.personName}),
        descriptionSectionId: (state) =>
            state.$store.getters["descriptionSectionId"],
        serviceTypes: (state) => state.$store.getters["serviceTypesMobile"],
        personType: (state) =>
            state.$store.getters["person/isCelebrity"] ? "celebrity" : "person",
    },
    watch: {
        descriptionSectionId() {
            this.rebuildCategories();
        },
        category() {
            this.rebuildCategories();
        },
    },
    created() {},
    mounted() {
        // Перестраиваем категории, чтобы отобразить только те сравнения и индикаторы, которые доступны для выбранного раздела
        this.rebuildCategories();
    },
    methods: {
        cardClass: function (t) {
            if (this.type === "all") {
                return t;
            }

            return this.type;
        },
        async getCompare(compare) {
            console.log("getCompare", compare);
            // Если нет авторизации - говорим об этом
            if (!this.isAuth()) return;

            // Если нет хотя бы одной фотографии - говорим об этом
            if (!this.myPhotos.profile || !this.myPhotos.anfas) {
                this.showUploadPhotosAttempt();
                return;
            }

            if (this.isMyProfile) {
                // Событие слушается в components/person/person.vue
                this.$bus.$emit("showPhotoCompare", compare);
                return;
            }

            this.activeType = compare;

            await this.getCompareResult(this.personUuid, compare.id)
                .then((r) => {
                    const result = r.data.compareWithPersonGet,
                        percent = result.resultValue,
                        title = result.resultTitle,
                        desc = result.resultDescription;

                    if (!percent) {
                        this.offerToBuyCompare(compare.id);
                        return;
                    }

                    this.$store.dispatch("setCompareResultDialog", {
                        show: true,
                        data: {
                            category: compare,
                            photo: this.personAvatar,
                            personUuid: this.isMyProfile ? "" : this.personUuid,
                            result: {
                                desc: desc,
                                title: title,
                                percent: percent,
                            },
                        },
                    });
                })
                .catch(() => {
                    this.$msgBox(
                        this.localeError("unknownError"),
                        this.localeError("retry"),
                    );
                });
        },
        async getIndicator(indicator) {
            console.log("getIndicator", indicator);
            // Если нет авторизации - говорим об этом
            if (!this.isAuth()) return;

            // Если нет хотя бы одной фотографии - говорим об этом
            if (!this.myPhotos.profile || !this.myPhotos.anfas) {
                this.showUploadPhotosAttempt();
                return;
            }

            this.activeType = indicator;

            await this.getIndicatorResult(this.personUuid, indicator.id)
                .then((r) => {
                    const result = r.data.personIndicatorGet,
                        percent = result.resultValue,
                        title = result.resultTitle,
                        desc = result.resultDescription;

                    if (!percent) {
                        this.offerToBuyIndicator(indicator.id);
                        return;
                    }

                    this.$store.dispatch("setCompareResultDialog", {
                        show: true,
                        data: {
                            category: indicator,
                            photo: this.personAvatar,
                            personUuid: this.isMyProfile ? "" : this.personUuid,
                            result: {
                                desc: desc,
                                title: title,
                                percent: percent,
                            },
                        },
                    });
                })
                .catch(() => {
                    this.$msgBox(
                        this.localeError("unknownError"),
                        this.localeError("retry"),
                    );
                });
        },
        rebuildCategories() {
            const status = [];
            Object.values(this.serviceTypes).find((r) => {
                if (this.type === "compare" && r.type === "compare") {
                    if (r.categories.indexOf(this.category) >= 0)
                        status.push(r);
                }
                if (this.type === "indicator" && r.type === "indicator") {
                    if (r.categories.indexOf(this.category) >= 0)
                        status.push(r);
                }
                if (this.type === "aggression" && r.type === "aggression") {
                    if (r.categories.indexOf(this.category) >= 0)
                        status.push(r);
                }
            });

            this.$emit("changeVisible", !!status.length);
        },
    },
};
</script>

<style src="./person-compare-mobile.scss" lang="scss" scoped></style>
