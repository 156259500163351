<template lang="pug">
  .person-mobile-business-card
    template(v-if="isPersonProfileReady")
      perfect-scrollbar._ctrl
        ._ctrl-item(:class="{ 'active': tab === 'merlin' }" @click="tab = 'merlin'") {{ locale("merlin") }}
        ._ctrl-item(:class="{ 'active': tab === 'user' }" @click="tab = 'user'") {{ locale("user", {user: profile.person.name}) }}
        ._ctrl-item(:class="{ 'active': tab === 'career' }" @click="tab = 'career'" v-if="profile.careers.length") {{ locale("career") }}
        ._ctrl-item(:class="{ 'active': tab === 'education' }" @click="tab = 'education'" v-if="profile.educations.length") {{ locale("education") }}

      ._inputs-wrap(v-if="tab === 'merlin'")
        another-person-business-card(:person="profile" show-section="merlin")

      ._inputs-wrap(v-if="tab === 'user'")
        another-person-business-card(:person="profile" show-section="user")

      ._inputs-wrap(v-if="tab === 'career'")
        another-person-business-card(:person="profile" show-section="career")

      ._inputs-wrap(v-if="tab === 'education'")
        another-person-business-card(:person="profile" show-section="education")
</template>

<script>
import DatePicker from "vue2-datepicker";
import ElementDecor from "@/components/ui/element-decor/element-decor";
import personProfileQuery from "@/graphql/queries/personProfile.query.graphql";
import gql from "graphql-tag";
import declination from "@/utils/declination";
import AnotherPersonBusinessCard from "@/components/person/another-person/another-person-business-card/another-person-business-card";

export default {
    name: "PersonMobileBusinessCard",
    components: { AnotherPersonBusinessCard, DatePicker, ElementDecor },
    props: {
        profilePreview: {
            required: false,
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        tab: "merlin",
        lang: {
            formatLocale: {
                firstDayOfWeek: 1,
            },
            monthBeforeYear: false,
        },
        profile: {},
        isPersonProfileReady: false,
    }),
    watch: {
        currentPerson() {
            this.getPersonProfile();
        },
    },
    async mounted() {
        await this.getPersonProfile();
    },
    methods: {
        declination,
        async getPersonProfile() {
            if (!this.currentPerson.uuid) return;
            await this.$apollo
                .query({
                    query: gql(personProfileQuery),
                    variables: {
                        id: this.currentPerson.uuid,
                    },
                })
                .then((r) => {
                    this.isPersonProfileReady = true;
                    this.profile = r.data.personProfile;
                });
        },
    },
};
</script>

<style lang="scss">
@import "./person-mobile-business-card.scss";
</style>
